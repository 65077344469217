<template>
  <div>
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li>
          <router-link :to="{ name: 'Dashboard' }">
            <b-icon icon="home"></b-icon>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'ReportIndex' }">
            Reporting
          </router-link>
        </li>
        <li class="has-text-primary-dark-blue">
          <span class="px-2">
            Add-On Sales This Month
          </span>
        </li>
      </ul>
    </nav>

    <div class="columns is-multiline">
      <div class="column is-3">
        <b-field>
          <b-input
            placeholder="Pencarian"
            v-model="search"
            icon="search"
            rounded
            @input="searchAddOnSales"
          ></b-input>
        </b-field>
      </div>

      <div class="column is-2 is-offset-7 ">
        <b-button
          @click="downloadExcel"
          class="button button-import"
          accept=".xlsx"
        >
          <span class="file">
            <img
              :src="require('../../assets/Export.png')"
              width="17%"
              alt=""
              class="mr-2"
            />
            <span class="file">Export</span>
          </span>
        </b-button>
      </div>

      <div class="column">
        <b-table
          :data="addons"
          :loading="loading"
          paginated
          backend-pagination
          :total="rows"
          :per-page="perPage"
          @page-change="onPageChange"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          backend-sorting
          :default-sort-direction="defaultSortOrder"
          :default-sort="[sortField, sortOrder]"
          @sort="onSort"
        >
          <b-table-column label="No" v-slot="props">
            {{ (page - 1) * perPage + props.index + 1 }}
          </b-table-column>

          <b-table-column
            field="addon_name"
            sortable
            label="Nama"
            v-slot="props"
          >
            {{ props.row.name }}
          </b-table-column>

          <b-table-column
            field="total_sales"
            label="Jumlah Penjualan"
            v-slot="props"
          >
            {{ props.row.totalSales | toCommas }}
          </b-table-column>

          <b-table-column
            field="total_price"
            label="Jumlah Pendapatan"
            v-slot="props"
          >
            {{ props.row.totalPrice | toCurrency }}
          </b-table-column>

          <template slot="bottom-left">
            <div class="has-text-left">
              Page {{ page }} From
              {{
                Math.ceil(rows / perPage) == '0'
                  ? '1'
                  : Math.ceil(rows / perPage)
              }}
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      addons: [],
      total: 0,
      loading: false,
      sortField: 'id',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      page: 1,
      perPage: 8,
      search: '',
    }
  },
  computed: {
    rows() {
      return this.total
    },
  },
  created() {
    this.getAddOnSales()
  },
  methods: {
    searchAddOnSales: debounce(function() {
      this.getAddOnSales()
    }, 500),
    getAddOnSales: function() {
      const params = [
        `sort_by=${this.sortField}`,
        `order=${this.sortOrder}`,
        `page=${this.page}`,
        `per_page=${this.perPage}`,
        `search=${this.search}`,
      ].join('&')
      this.loading = true

      this.$store.dispatch('getAddOnSales', params).then(response => {
        if (response != null) {
          this.addons = response.data
          this.total = response.total
        } else {
          this.addons = []
        }
        this.loading = false
      })
      // this.loading = false
    },
    onPageChange(page) {
      this.page = page
      this.getAddOnSales()
    },
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.getAddOnSales()
    },
    downloadExcel() {
      this.$store.dispatch('downloadExcelAddOn')
    },
  },
}
</script>

<style>
.button-import {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  overflow: hidden;
  outline: none;
}
</style>
